<template>
  <div id="Lift">
    <moModel :moData="moData"></moModel>
  </div>
</template>

<script>
import moModel from "../home/components/models";

import { get } from "@get/http";
export default {
  name: "ProductList",
  components: {
    moModel,
  },
  data: () => ({
    moData: [],
    height: 0,
  }),
  props: {
    iconHeight: {},
  },
  created() {
    this.getIndex();
  },
  methods: {
    async getIndex() {
      let { page_config } = await get("/index/mofang", {
        params: {
          store_id: 2,
          id: 36,
          page_type: 0,
        },
      });
      this.moData = page_config;
    },
  },
};
</script>

<style lang="scss">
#Lift {
  margin-bottom: 70px;
}
</style>
